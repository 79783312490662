.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: 'Mulish';
  color:#152A3D;
  text-align: center;
}

.cardContainer {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    justify-content: center;
}

@media(min-width:480px) {
            .cardContainer {
                    margin: -15px;
                    margin-top: 25px;
                    display: flex;
                    flex-wrap: wrap;
                    grid-gap: 0;
                    }
                    }
@media (min-width: 768px)  {
.cardContainer {
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
.cardContainer {
        margin-top: 65px;
    }
}

@media (min-width: 1600px) {

}