.theCard {
  width: 100%;
    max-width: 290px;
  aspect-ratio : 1 / 1;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
  font-family: 'Mulish', sans-serif;
  text-decoration: none;
  color:#012310;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.theCard:hover,
.theCard:focus {
  transform: scale(1.05);
  transition: all 0.5s ease-out;
}

.theFront {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}   

.backDescription  {
  display: none;
}

.description {
  display: none;
}

.theFront .title { 
  margin-top: 2vw;
  font-weight: 700;
  font-size: 12px;
  font-size: 3vw;
  line-height: 3.5vw;
  text-align: center;
  color: #012310;
}

.theFront .img {
  height: 12vw;
  width: fit-content;
}

@media (min-width: 480px) {
  .theCard {
    width: calc(50% - 30px);
    margin: 15px;
  }
}

@media (min-width: 768px) {
  .theCard {
      padding: 10px 20px;
    }
  }
  .theFront .img{
    height: 105px;
  }

  .theFront .title { 
    margin-top: 30px;
    font-size: 18px;
    line-height: 125%;
  
}

@media (min-width: 1200px)  {
  .theCard {    
    width: 286px;
    height: 100%;
  }
      
}

@media (min-width: 1920px) {
  .theCard {
    width: calc(20% - 30px);
      justify-content: flex-start;
      padding: 15px;
  }
}