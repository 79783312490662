.section{
background-color: #F5FFF9;
    font-family: 'Mulish', sans-serif;
}

.container {
width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.promoCard{
display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 25px;
    width: 100%;
    max-width: 390px;
    text-align: center;
    background-color: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(67, 72, 69, 0.2);
    margin-bottom: 25px;
}

.title{
font-weight: 700;
}

.description{
margin-top: 15px;
}

.titlebtn{
text-transform: uppercase;
    margin-top: 24px;
    
}

.btnprim{
text-transform: uppercase;
}

.btn {
margin-top: 25px;
}

.carousel{
width: 100%;
    z-index: 2;
    padding: 0 20px;
}

.slide_demo{
width: 100%;
    height: auto;
}

.carouselItem {
width: 100%;
    height: auto;
}

@media (min-width: 768px) {
.promoCard {
        margin-bottom: 35px;
    }
}

@media (min-width: 1200px) { 
.container {
        flex-direction: row;
    }

  .promoCard {
      width: 608px;
      margin-bottom: 0;
      margin-right: 50px;
  }
}